import { Component, EventEmitter, Input, Output, signal } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { MatError, MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { InquiryAccessService } from '../../services/inquiry-access.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { InquiryAccessError } from '../../model/inquiry/inquiry-access-error';
import { InquiryAccess } from '../../model/inquiry/inquiry-access';

@Component({
  selector: 'app-inquiry-access-token-input',
  standalone: true,
  imports: [
    MatButton,
    MatError,
    MatFormField,
    MatInput,
    MatLabel,
    ReactiveFormsModule,
    MatProgressSpinner,
  ],
  templateUrl: './inquiry-access-token-input.component.html',
  styleUrl: './inquiry-access-token-input.component.scss',
})
export class InquiryAccessTokenInputComponent {
  @Input() inquiryId: string;
  @Output() accessTokenSetSuccessfully = new EventEmitter<string>();

  protected readonly digits = 6;

  protected errorMessage$ = signal('');
  protected isLoading$ = signal(false);

  readonly accessToken = new FormControl('', [
    Validators.required,
    Validators.minLength(this.digits),
    Validators.maxLength(this.digits),
  ]);

  constructor(
    private readonly inquiryAccessService: InquiryAccessService,
    private readonly snackBar: MatSnackBar,
  ) {}

  protected updateErrorMessage() {
    if (this.accessToken.hasError('required')) {
      this.errorMessage$.set($localize`Bitte geben Sie den Zugangscode ein`);
    } else if (
      this.accessToken.hasError('minlength') ||
      this.accessToken.hasError('maxlength')
    ) {
      this.errorMessage$.set(
        $localize`Der Zugangscode muss genau ${this.digits} Zeichen haben`,
      );
    } else {
      this.errorMessage$.set('');
    }
  }

  protected onSendAccessToken() {
    if (this.accessToken.valid) {
      const token = this.accessToken.value;
      if (token) {
        this.isLoading$.set(true);
        this.inquiryAccessService
          .accessInquiry(this.inquiryId, token)
          .subscribe({
            next: (res) => {
              this.handleInquiryAccess(res, token);
            },
            error: () => {
              this.showInvalidAccessTokenSnackBar();
            },
            complete: () => {
              this.isLoading$.set(false);
            },
          });
      }
    }
  }

  private handleInquiryAccess(res: InquiryAccess, token: string) {
    if (res.canAccess) {
      this.accessTokenSetSuccessfully.next(token);
    } else if (res.error === InquiryAccessError.AccessTokenEnteredTooOften) {
      this.showAccessTokenEnteredTooOftenSnackBar();
    } else {
      this.showInvalidAccessTokenSnackBar();
    }
  }

  private showInvalidAccessTokenSnackBar() {
    this.snackBar.open(
      $localize`Der eingegebene Zugangscode scheint nicht gültig zu sein.`,
      $localize`Ok`,
      { duration: 5_000 },
    );
  }

  private showAccessTokenEnteredTooOftenSnackBar() {
    this.snackBar.open(
      $localize`Der Zugangscode wurde zu oft falsch eingegeben. Kontaktieren Sie das Unternehmen wenn Sie noch Zugriff auf die Daten benötigen.`,
      $localize`Ok`,
      { duration: 10_000 },
    );
  }
}
