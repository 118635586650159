import { Injectable } from '@angular/core';
import { map, Observable, ReplaySubject, tap } from 'rxjs';
import { InquirySummaryService as InquirySummaryApi } from '../../api/gen';
import { InquirySummary } from '../model/inquiry-summary';
import { InquiryJoin } from '../model/inquiry-join';

@Injectable()
export class InquirySummaryService {
  private readonly inquirySummary$ = new ReplaySubject<InquirySummary>(1);
  private readonly inquiryJoinInfo$ = new ReplaySubject<InquiryJoin>(1);

  constructor(private readonly inquirySummaryService: InquirySummaryApi) {}

  getInquirySummary(): Observable<InquirySummary> {
    return this.inquirySummary$.asObservable();
  }

  getInquiryJoinInfo(): Observable<InquiryJoin> {
    return this.inquiryJoinInfo$.asObservable();
  }

  public loadInquirySummary(
    inquiryId: string,
    accessToken?: string,
  ): Observable<InquirySummary> {
    return this.inquirySummaryService
      .customerContactSummaryGet(inquiryId, accessToken)
      .pipe(
        map((dto) => InquirySummary.fromDto(dto)),
        tap((summary) => this.inquirySummary$.next(summary)),
      );
  }

  public loadInquiryJoinInfo(inquiryId: string): Observable<InquiryJoin> {
    return this.inquirySummaryService
      .customerContactSummaryJoinInfoGet(inquiryId)
      .pipe(
        map((dto) => InquiryJoin.fromDto(dto)),
        tap((joinInfo) => this.inquiryJoinInfo$.next(joinInfo)),
      );
  }
}
